import { FC, memo } from "react";

import querystring from "querystring";
import Utils from "utils/Utils";
import styles from "./Social.module.scss";

const PHONE = "919354062108";
const MESSAGE =
  "Hey Team! Loved your experiences, would love to know more about them.";

export const WhatsAppLink = () => (
  <a
    href={`https://api.whatsapp.com/send?${querystring.stringify({
      phone: PHONE,
      text: MESSAGE,
    })}`}
    rel="noopener noreferrer"
    target="_blank"
  >
    <div className="d-flex  align-items-center justify-content-center">
      <img
        height={`${24}px`}
        src={Utils.cdnUrl("webImages/icons/whatsapp.svg")}
        alt="Whatsapp SVG"
      />
    </div>
  </a>
);

const WhatsAppBtnWithIcon: FC = () => (
  <div
    style={{
      height: "48px",
      width: "48px",
    }}
    className={`${styles.whatsAppBtn}`}
  >
    <WhatsAppLink />
  </div>
);

export default memo(WhatsAppBtnWithIcon);
