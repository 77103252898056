import app from "firebase/app";
import "firebase/auth";
import { ComponentType, createContext, FC, useContext } from "react";

let firebaseConfig = {
  apiKey: "AIzaSyC_7_-QaA4SA2G3b9gBcK_KBIIbmqRJqvY",
  authDomain: "bookweekendtours.firebaseapp.com",
  databaseURL: "https://bookweekendtours.firebaseio.com",
  projectId: "bookweekendtours",
  storageBucket: "bookweekendtours.appspot.com",
  messagingSenderId: "432240154618",
  appId: "1:432240154618:web:76fb2496d45f6ce96fb643",
  measurementId: "G-4GJF2NQBTD",
};

export const FirebaseContext = createContext(null);

export const withFirebase = <P extends object>(
  Component: ComponentType<P>
): FC<P> => (props) => (
  <FirebaseContext.Consumer>
    {(firebase) => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export function useFirebase(): Firebase {
  return useContext(FirebaseContext);
}

export default class Firebase {
  constructor() {
    try {
      app.initializeApp(firebaseConfig);
    } catch (e) {}
  }

  createRecaptchaVerifier = (container, params) =>
    new app.auth.RecaptchaVerifier(container, params);

  doCreateUserWithMobile = (mobile, appVerifier) =>
    app.auth().signInWithPhoneNumber(mobile, appVerifier);

  signOutUserFromOTPLogin = () => app.auth().signOut();

  disableStupidCapatcha = () =>
    (app.auth().settings.appVerificationDisabledForTesting = true);
}
