import "styles/globals.scss";

import Router, { useRouter } from "next/router";
import { get, getByQueryParam } from "services/api/apiService";
import CommonProvider, {
  CommonProviderProps,
} from "services/contextProviders/CommonProvider";
import Firebase, { FirebaseContext } from "utils/Firebase";

import WhatsAppBtnWithIcon from "common/social/WhatsAppBtnWithIcon";
import LogRocket from "logrocket";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import AuthProvider from "services/AuthService/AuthProvider";
import Analytics from "utils/Analytics";
import { serverLogger } from "utils/Logger";

LogRocket.init("vyadjb/bwt-experiences");
const TopProgressBar = dynamic(() => import("common/loaders/TopProgress"), {
  ssr: false,
});
const fcWidgetCallBack = (path: string) => {
  const routesWhichHidesWidget = path.includes("/checkout");

  if (window?.fcWidget) {
    if (routesWhichHidesWidget) {
      window.fcWidget.hide();
    } else {
      window.fcWidget.show();
    }
  }
};

Router.events.on("routeChangeComplete", fcWidgetCallBack);

function MyApp({ Component, pageProps, locations, categories, config }) {
  const router = useRouter();

  useEffect(() => {
    setTimeout(fcWidgetCallBack, 1000, router.asPath);
  }, [router.asPath]);

  useEffect(() => {
    if (router?.query?.referral_code) {
      Analytics.track("Referral set in local storage");
      localStorage.setItem(
        "referral_code",
        `${router?.query?.referral_code}`.toUpperCase()
      );
    }
  }, [router?.query?.referral_code]);

  useEffect(() => {
    // if any image tag found with src cdn.bookweekendtours.com then replace it with cdn.bwtexperiences.com
    const images = document.querySelectorAll("img");
    images.forEach((image) => {
      if (image.src.includes("cdn.bookweekendtours.com")) {
        image.src = image.src.replace(
          "cdn.bookweekendtours.com",
          "cdn.bwtexperiences.com"
        );
      }
    });
    // if any a tag found with picture source tag cdn.bookweekendtours.com then replace it with cdn.bwtexperiences.com
    const sourceTags = document.querySelectorAll("source");
    sourceTags.forEach((source) => {
      if (source.srcset.includes("cdn.bookweekendtours.com")) {
        source.srcset = source.srcset.replace(
          "cdn.bookweekendtours.com",
          "cdn.bwtexperiences.com"
        );
      }
    });
  }, [router.asPath]);

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>
      <TopProgressBar />
      <FirebaseContext.Provider value={new Firebase()}>
        <AuthProvider>
          <CommonProvider
            locations={locations}
            categories={categories}
            config={config}
          >
            <Toaster />
            <WhatsAppBtnWithIcon />
            <Component {...pageProps} />
          </CommonProvider>
        </AuthProvider>
      </FirebaseContext.Provider>
    </>
  );
}

MyApp.getInitialProps = async ({ ctx }): Promise<CommonProviderProps> => {
  const params = {
    limit: 10,
    page: 1,
  };

  try {
    const [
      { data: locations },
      { data: categories },
      { data: config },
    ] = await Promise.all([
      getByQueryParam("v1/locations/", { ...params, best_selling: true }),
      getByQueryParam("v1/categories/", { ...params, is_tripflix: false }),
      get("v1/categories/config/"),
    ]);

    return {
      locations,
      categories,
      config,
    };
  } catch (e) {
    serverLogger(e);
    return {
      locations: [],
      categories: [],
      config: [],
    };
  }
};

export default MyApp;
